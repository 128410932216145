<template>
  <header v-if="video" :class="headerClass" class="page-header">
    <VideoBackground
      :src="video"
      ref="videobackground"
      :poster="backgroundImage"
    >
      <div class="container">
        <div class="page-header__content" :class="contentClass">
          <h2>{{ title }}</h2>
          <slot class="page-header__intro"></slot>
        </div>
      </div>
    </VideoBackground>
  </header>
  <header
    v-else
    class="page-header"
    :class="headerClass"
    :style="computedStyle"
  >
    <div class="container">
      <div class="page-header__content" :class="contentClass">
        <h2>{{ title }}</h2>
        <slot class="page-header__intro"></slot>
      </div>
    </div>
  </header>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player';
export default {
  name: 'PageHeader',
  components: {
    VideoBackground,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    backgroundImage: {
      type: String,
      required: false,
      default: '',
    },
    backgroundColor: {
      validator: (propValue) => {
        // The value must match one of these strings
        return ['white', 'red', 'black', 'transparent'].includes(propValue);
      },
      default: 'transparent',
    },
    textColor: {
      type: String,
      validator: (propValue) => {
        // The value must match one of these strings
        return ['white', 'black'].includes(propValue);
      },
      default: 'black',
    },
    video: {
      type: String,
      required: false,
      default: null,
    },
    overlay: {
      type: String,
      required: false,
      default: '',
    },
    diagonal: {
      type: String,
      required: false,
      default: '',
      validator: (propValue) => {
        // The value must match one of these strings
        return ['light', 'dark'].includes(propValue) || propValue == '';
      },
    },
    align: {
      type: String,
      required: false,
      validator: (propValue) => {
        // The value must match one of these strings
        return ['start', 'center', 'end'].includes(propValue);
      },
      default: 'center',
    },
    size: {
      type: String,
      validator: (propValue) => {
        // The value must match one of these strings
        return ['lg', 'md', 'sm'].includes(propValue);
      },
      default: 'md',
    },
  },
  computed: {
    headerClass() {
      let cls = [];
      switch (this.overlay) {
        case 'medium':
          cls.push('overlay');
          break;
        case 'light':
          cls.push('overlay overlay--light');
          break;
        case 'dark':
          cls.push('overlay overlay--dark');
          break;
      }
      if (this.backgroundImage && !this.video) {
        cls.push('bg-image');
      }
      if (this.backgroundColor) {
        cls.push('bg-' + this.backgroundColor);
      }
      if (this.textColor) {
        cls.push('text-' + this.textColor);
      }
      if (this.diagonal) {
        cls.push('bg-diagonal--' + this.diagonal);
      }

      cls.push('page-header--' + this.size);

      return cls;
    },
    contentClass() {
      let cls = [];
      cls.push('justify-content-' + this.align);
      cls.push('text-' + this.align);
      return cls;
    },
    computedStyle() {
      let style = [];
      if (this.backgroundImage) {
        style.push({
          backgroundImage: `url(${require('@/assets/img/' +
            this.backgroundImage)})`,
        });
        // style.push({ property: 'value' });
      }
      return style;
    },
    element() {
      if (this.video) {
        return 'VideoBackground';
      } else {
        return 'div';
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.page-header {
  width: 100%;
  text-shadow: 0 1px 3px fade-out($black, 0.5);
  position: relative;
  min-height: 0;
  &.bg-image {
    background-size: cover;
    background-position: center center;
    position: relative;
  }
  &.overlay {
    &::before,
    .video-wrapper::before {
      content: ' ';
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.333);
      z-index: 1;
    }
    &--light {
      &::before,
      .video-wrapper::before {
        background: rgba(0, 0, 0, 0.25);
      }
    }
    &--dark {
      &::before,
      .video-wrapper::before {
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4rem 0;
    z-index: 2;
    @include media-breakpoint-up(md) {
      .page-header--sm & {
        padding: 2rem 0;
      }
      .page-header--md & {
        padding: 6rem 0;
      }
      .page-header--lg & {
        padding: 8rem 0;
      }
    }
    @include media-breakpoint-up(lg) {
      // padding-left: 8rem;
      // padding-right: 8rem;
    }
    p {
      font-size: $text-lg;
      margin-top: 1rem;
      @include media-breakpoint-up(md) {
        font-size: $text-xl;
      }
    }
  }
  &__title {
    @include heading-base;
    font-size: $header-md;
    @include media-breakpoint-up(md) {
      font-size: 5rem;
    }
  }
}

.vue-responsive-videobg {
  .video-wrapper {
    &::before {
      content: ' ';
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.333);
    }
    &--light {
      &::before {
        background: rgba(0, 0, 0, 0.25);
      }
    }
    &--dark {
      &::before {
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
  .videobg-content {
    position: relative !important;
  }
}
</style>
