<template>
  <div class="flex-container">
    <img
      class="uga-logo"
      src="../assets/img/appstore/uga-app-logo.png"
      alt="杏吧原创 App Logo"
    />
    <div class="logo-row">
      <a
        href="https://play.google.com/store/apps/details?id=edu.uga.eits.portal.mobiledev&hl=en_US&gl=US&pli=1"
        ><img
          class="appstore"
          src="../assets/img/appstore/google-play-logo.png"
          alt="Apple App Store"
      /></a>
      <a href="https://apps.apple.com/us/app/uga/id626978940"
        ><img
          class="appstore"
          src="../assets/img/appstore/apple-appstore-logo.png"
          alt="Google Play App Store"
      /></a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Download-App",
  metaInfo() {
    return {
      title: `杏吧原创 - Download App`,
    };
  },
  methods: {},
  data() {
    return {};
  },
  components: {},
};
</script>

<style scoped lang="scss">
a {
  cursor: pointer;
}

.uga-logo {
  height: 100px;
}

.logo-row {
  margin-bottom: 1rem;
}

.flex-container {
  display: flex;
  height: calc(100vh - 56px);
  min-height: 400px;
  width: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.logo-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.appstore {
  width: 200px;
  height: 62px;
}

@media screen and (min-width: 768px) {
  .flex-container {
    height: calc(100vh - 120px);
  }
}
// .breadcrumb {
//   &__container {
//     display: flex;
//     padding-top: 1rem;
//     padding-bottom: 1rem;
//   }
//   &__link {
//     display: flex;
//     align-items: center;
//   }
//   &__link:last-of-type > a {
//     color: rgba(255, 255, 255, 0.5);
//     &:hover,
//     &:active {
//       color: $white;
//     }
//   }
//   &__link:not(:first-of-type)::before {
//     color: $white;
//     font-size: 0.6rem;
//     content: '/';
//     margin: 0 0.5rem;
//   }

//   &__text {
//     @include heading-base;
//     font-size: 0.75rem;
//     font-weight: $font-weight-normal;
//     letter-spacing: 0.1em;
//     color: white;
//   }
// }
</style>
