<template>
  <main class="403">
    <Breadcrumb :items="['home']"></Breadcrumb>

    <PageHeader
      title="403: Forbidden"
      backgroundColor="red"
      textColor="white"
      align="center"
      diagonal="dark"
      size="sm"
    >
    </PageHeader>
    <div class="py-5">
      <div class="container text-center">
        <p class="lead">
          <strong>The server cannot find the requested resource.</strong>
        </p>
        <p>
          If you鈥檙e looking for something specific, try using the search button
          above. If you still can鈥檛 find what you need, send an email to
          <a href="mailto:webmaster@uga.edu">webmaster@uga.edu</a> and we鈥檒l do
          our best to help!
        </p>
      </div>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import Breadcrumb from "@/components/Breadcrumb.vue";
import PageHeader from "@/components/PageHeader.vue";

export default {
  name: "403",
  metaInfo() {
    return {
      title: `Forbidden`,
      meta: [{ name: "robots", content: "noindex" }],
    };
  },
  methods: {},
  data() {
    return {};
  },
  components: {
    PageHeader,
    Breadcrumb,
  },
};
</script>

<style scoped lang="scss"></style>
